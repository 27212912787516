import { Block } from '@/components/block';
import { Link } from '@/components/link';
import { GenericLinkInterface } from '@/types';

interface Props {
  title: string;
  links: GenericLinkInterface[];
  noTopPadding?: boolean;
  id?: string;
}

const LinkBank = ({ title, links, noTopPadding = false, id = 'link-bank' }: Props) => {
  return (
    <Block id={id} heading={title} headingVariant="h2" noTopPadding={noTopPadding}>
      <ul className="grid grid-cols-2 gap-x-6 gap-y-4 md:grid-cols-3 lg:grid-cols-4">
        {links.map((link) => (
          <li className="leading-none" key={link.url}>
            <Link href={link.url} className="decoration-none leading-snug hover:text-lightBlue-600 hover:underline">
              {link.value}
            </Link>
          </li>
        ))}
      </ul>
    </Block>
  );
};

export { LinkBank };
