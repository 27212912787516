import { GetStaticProps } from 'next';
import type { ReactElement } from 'react';
import { useQuerySubscription } from 'react-datocms';

import { ContentMatrix } from '@/components/content-matrix';
import { PageLayout } from '@/components/layouts/page-layout';
import { LinkBank } from '@/components/link-bank';
import { createDatoSubscription } from '@/lib/api/utils/create-dato-subscription';
import { PAGE_REVALIDATE_INTERVAL_WEEK, SITE_DOMAIN } from '@/lib/constants';
import { DatoQueryListenerOptions } from '@/types/cms-types';
import { cmsService } from '@/utils/api/services/cms.service';

type PageProps = { subscription: DatoQueryListenerOptions };

const Page = ({ subscription }: PageProps): ReactElement => {
  const {
    data: { page },
    status,
    error,
  } = useQuerySubscription(subscription);

  return (
    <PageLayout
      metaTags={page.seoMetaTags}
      canonical={SITE_DOMAIN}
      previewStatus={{ status, error }}
      headerVariant="light"
    >
      <ContentMatrix blocks={page.blocks} />
      <LinkBank
        title="Popular shipping routes"
        links={[
          { url: '/routes/tokyo-jp/los-angeles-us', value: 'Tokyo to Los Angeles' },
          { url: '/routes/london-gb/istanbul-tr', value: 'London to Istanbul' },
          { url: '/routes/new-york-us/genoa-it', value: 'New York to Genoa' },
          { url: '/routes/frankfurt-de/atlanta-us', value: 'Frankfurt to Atlanta' },
          { url: '/routes/sydney-au/shanghai-cn', value: 'Sydney to Shanghai' },
          { url: '/routes/brisbane-au/ningbo-cn', value: 'Brisbane to Ningbo' },
          { url: '/routes/melbourne-au/shenzhen-cn', value: 'Melbourne to Shenzhen' },
          { url: '/routes/durban-za/singapore-sg', value: 'Durban to Singapore' },
          { url: '/routes/ho-chi-minh-city-vn/paris-fr', value: 'Ho Chi Minh City to Paris' },
          { url: '/routes/shanghai-cn/los-angeles-us', value: 'Shangai to Los Angeles' },
          { url: '/routes/halifax-ca/london-gb', value: 'Halifax to London' },
          { url: '/routes/chicago-us/amsterdam-nl', value: 'Chicago to Amsterdam' },
          { url: '/routes/singapore-sg/manila-ph', value: 'Singapore to Manila' },
          { url: '/routes/busan-kr/shanghai-cn', value: 'Busan to Shanghai' },
          { url: '/routes/hamburg-de/dubai-ae', value: 'Hamburg to Dubai' },
          { url: '/routes/mumbai-in/antwerp-be', value: 'Mumbai to Antwerp' },
          { url: '/routes/hong-kong-hk/brisbane-au', value: 'Hong Kong to Brisbane' },
          { url: '/routes/barcelona-es/cartagena-co', value: 'Barcelona to Cartagena' },
          { url: '/routes/oakland-us/taipei-tw', value: 'Oakland to Taipei' },
          { url: '/routes/houston-us/jeddah-sa', value: 'Houston to Jeddah' },
        ]}
      />
    </PageLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ preview = false }) => {
  const page = await cmsService.page.getOne('home', preview);

  if (!page || !page.slug) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      subscription: await createDatoSubscription({ page, slug: 'home', preview }),
    },
    revalidate: PAGE_REVALIDATE_INTERVAL_WEEK,
  };
};

export default Page;
